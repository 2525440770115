/* eslint-disable */
import React from 'react'

import Agent from '../../images/people/agent.jpg'

import BirthdayParty from '../../images/people/birthday.jpg'
import CoupleOnComputer from '../../images/people/couple-on-computer.jpg'
import Pharmacy from '../../images/people/pharmacy.jpg'
import grayImgBackground from '../../images/backgrounds/gray-img-background.jpg'
import dottedPurpleBackground from '../../images/backgrounds/dotted-purple-background.jpg'
import purpleImgBackground from '../../images/backgrounds/purple-img-background.jpg'
import { StaticImage } from 'gatsby-plugin-image'

import {
  Billboard,
  Carousel,
  Columns,
  Column,
  Link,
  LinkButton,
  List,
  ListItem,
  SplitContent,
  Stack,
  Typography,
  VariableContent,
  useMapi,
} from '@leshen/gatsby-theme-leshen'
import { Layout } from '@leshen/gatsby-theme-contentful'

import header from '../../components/Header'
import footer from '../../components/Footer'
import NarrowBanner from '../../components/NarrowBanner'

import aetna from '../../images/brand-logos/aetna.svg'
import cigna from '../../images/brand-logos/cigna.svg'
import regence from '../../images/brand-logos/regence.svg'
import united from '../../images/brand-logos/united-healthcare.svg'
import wellcare from '../../images/brand-logos/wellcare.svg'
import mutual from '../../images/brand-logos/mutual-omaha.svg'

import ResponsiveGrid from '../../components/GridLayouts/ResponsiveGrid'

const Home = () => {
  const { requestId, sessionPromoCode, rotatedNumber } = useMapi()

  const brandLogos = [
    { src: aetna, alt: 'Aetna' },
    { src: cigna, alt: 'Cigna' },
    { src: regence, alt: 'Regence' },
    { src: united, alt: 'United Healthcare' },
    { src: wellcare, alt: 'Wellcare' },
    { src: mutual, alt: 'Mutual of Omaha' },
  ]

  const layoutProps = {
    data: {
      contentfulPage: {
        header: header(),
        footer: footer(),
        seo: {
          title:
            'Learn About Medicare General and Open Enrollment Periods | Clearlinkinsurance.com',
          description:
            'Learn about two lesser-known Medicare enrollment periods that run from January 1 to March 31. See if you qualify for an enrollment period to review your medicare coverage.',
          canonical: 'https://clearlinkinsurance.com/about-us',
          robots: 'follow,index',
        },
        path: '/resources/open-and-general-enrollment-periods',
        promoCode: '169930',
        ringPool: 'CLI',
        hideStickyCTA: false,
        spanish: false,
        sections: [
          {
            // Add any relevant section data
          },
        ],
      },
      site: {
        siteMetadata: {
          siteURL: 'https://clearlinkinsurance.com/',
          siteName: 'clearlinkinsurance',
          alternateName: 'clearlinkinsurance',
          removeTrailingSlashes: true,
        },
      },
    },
    titleTemplate:
      'Learn About Medicare General and Open Enrollment Periods | Clearlinkinsurance.com',
    defaultRobots: 'follow,index',
    main: (
      <>
        <VariableContent
          mainContent={
            <>
              <Typography variant="h1" className="text-left-on-small">
                Do You Qualify for Medicare Enrollment Outside of Annual
                Enrollment Period (AEP): GEP and MAOEP
              </Typography>
              <Typography variant="body" className="text-left-on-small">
                Read on below to learn about the Medicare General Enrollment
                Period (GEP) and the Medicare Advantage Open Enrollment Period
                (MAOEP) which run concurrently from January 1 through March 31.
              </Typography>
            </>
          }
          alignMainContent="Center"
          className="dotted-purple-background"
          style={{
            backgroundImage: `url(${dottedPurpleBackground})`,
            color: '#fff',
          }}
        ></VariableContent>

        <div className="purple-img-background hide-nav-button">
          <VariableContent
            mainContent={
              <>
                <Typography variant="h2">Key Takeaways</Typography>
              </>
            }
            style={{
              backgroundImage: `url(${purpleImgBackground})`,
              color: '#fff',
            }}
          >
            <Stack spacing="l">
              <Columns cardStyled mobileCarousel>
                <Column backgroundColor="primary">
                  <StaticImage
                    src="../../images/illustrations/calendar.svg"
                    alt="star ratings"
                    layout="fixed"
                    width={88}
                    height={88}
                    className="product-icons"
                  />
                  <Typography variant="body">
                    GEP and MAOEP are two additional lesser-known enrollment
                    periods running Jan 1 - Mar 31.
                  </Typography>
                </Column>
                <Column backgroundColor="primary">
                  <StaticImage
                    src="../../images/illustrations/sixty-five.svg"
                    alt="star ratings"
                    layout="fixed"
                    width={88}
                    height={88}
                    className="product-icons"
                  />
                  <Typography variant="body">
                    GEP (General Enrollment Period) allows enrollment for those
                    who missed their age 65 Initial Enrollment Period
                  </Typography>
                </Column>
                <Column backgroundColor="primary">
                  <StaticImage
                    src="../../images/illustrations/choices.svg"
                    alt="star ratings"
                    layout="fixed"
                    width={88}
                    height={88}
                    className="product-icons"
                  />
                  <Typography variant="body">
                    MAOEP allows existing Medicare Advantage holders to make
                    plan changes
                  </Typography>
                </Column>
              </Columns>
            </Stack>
          </VariableContent>
        </div>

        <VariableContent
          mainContent={
            <>
              <StaticImage src="../../images/people/couple.jpg" alt="couple" />
              <Typography variant="h2" className="text-center">
                Medicare Enrollment Period General Information
              </Typography>
              <Typography variant="body" className="text-left">
                As you approach Medicare eligibility (occurring for most
                Americans in their age 65 year), it’s important to get
                acquainted with the various times of year—known as enrollment
                periods—when you can sign up for Medicare coverage. Medicare’s
                two primary enrollment periods are:
              </Typography>
              <List>
                <ListItem>
                  The Initial Enrollment Period (IEP), which begins three months
                  before your 65th birthday, includes your birthday month, and
                  continues for three months following. When you enroll in
                  Medicare during IEP, your coverage begins the month following
                  signup.
                </ListItem>
                <ListItem>
                  The Annual Enrollment Period (AEP) lasts from October 15
                  through December 7 every year. During this period, those
                  already enrolled in Medicare can join or drop a Medicare
                  Advantage plan, switch Medicare Advantage plans, add or drop
                  prescription coverage, switch from Original Medicare to a
                  Medicare Advantage plan, or vice-versa.
                </ListItem>
              </List>
              <Typography variant="body">
                Beyond these two primary enrollment periods, the window from
                January 1 through March 31 comprises two additional enrollment
                periods for which you might be eligible: the General Enrollment
                Period (for those who did not sign up for Medicare in their
                first eligible year) and the Medicare Advantage Open Enrollment
                Period (for those who wish to make changes to an existing
                Medicare Advantage plan).
              </Typography>
            </>
          }
          alignMainContent="left"
        ></VariableContent>

        <SplitContent
          backgroundColor="white"
          className="gray-bg bg-overwrite"
          alignImageToBottom={false}
          image={<img src={BirthdayParty} alt="birthday" />}
          mobileImage={false}
          mainContent={
            <>
              <Typography variant="h2" className="text-left-on-small">
                More on the General Enrollment Period and GEP eligibility
              </Typography>
              <Typography variant="body" className="text-left-on-small">
                The Medicare General Enrollment Period (GEP) is another chance
                to apply for Medicare coverage if you missed your Initial
                Enrollment Period (the 7 months surrounding your 65th birthday,
                detailed above). GEP lasts from January 1 through March 31 every
                year. You are eligible to use the GEP the first January through
                March after your 65th birthday year ends.
              </Typography>
            </>
          }
        />

        <SplitContent
          alignImageToBottom={false}
          image={<img src={CoupleOnComputer} alt="Couple on computer" />}
          mobileImage={false}
          mainContent={
            <>
              <Typography variant="h2" className="text-left-on-small">
                Enrolling in Medicare during the GEP
              </Typography>
              <Typography variant="body" className="text-left-on-small">
                As long as you are eligible, there are two ways to enroll in
                Medicare during the GEP:
              </Typography>
              <List>
                <ListItem>
                  In-person or over-the-phone enrollment with your local Social
                  Security office
                </ListItem>
                <ListItem>
                  Online enrollment via{' '}
                  <Link
                    to="https://www.ssa.gov/medicare/sign-up"
                    target="_blank"
                  >
                    https://www.ssa.gov/medicare/sign-up 
                  </Link>
                </ListItem>
              </List>
              <Typography variant="body" className="text-left-on-small">
                Enrolled benefits from the GEP begin on July 1 of the enrollment
                year.
              </Typography>
              <Typography variant="body" className="text-left-on-small">
                Want to learn more about GEP enrollment? Call our helpful agents
                at <br />
                <Link to={`tel:${rotatedNumber}`}>{rotatedNumber}</Link> for
                assistance.
              </Typography>
            </>
          }
        />

        <VariableContent
          className="gray-bg bg-overwrite"
          mainContent={
            <>
              <Typography
                variant="h2"
                className="text-left-on-small text-center"
              >
                More on the Medicare Advantage Open Enrollment Period and MAOEP
                eligibility
              </Typography>
              <Typography variant="body">
                Though it runs concurrent with the GEP, the Medicare Advantage
                Open Enrollment Period (MAOEP) is for an entirely different
                group of people—only current Medicare Advantage plan holders are
                eligible for MAOEP. 
              </Typography>
              <Typography variant="body">
                This period, following shortly after the Annual Enrollment
                Period of October 15 through December 7, allows plan holders to
                double-check their plans to make sure they cover the correct
                doctors and prescriptions and fit the policyholder’s budget. 
              </Typography>
              <Typography variant="body">
                IMPORTANT NOTE—you may not enroll in a Medicare Advantage plan
                or any other type of coverage for the first time during MAOEP;
                this period exists to change existing coverage.
              </Typography>
            </>
          }
          alignMainContent="Left"
        ></VariableContent>

        <SplitContent
          backgroundColor="lightgray"
          alignImageToBottom={false}
          image={<img src={Pharmacy} alt="Pharmacy" />}
          mobileImage={false}
          mainContent={
            <>
              <Typography variant="h2">
                What you can and can’t do during MAOEP
              </Typography>
              <Typography variant="body">
                Once a year outside of AEP, from January 1 through March 31, the
                Medicare Advantage Open Enrollment Period allows you to change
                your existing Medicare Advantage plan.
              </Typography>
              <Typography variant="body">
                <strong>What you can do:</strong>
              </Typography>
              <List>
                <ListItem>
                  Add prescription drug coverage (Medicare Part D)
                </ListItem>
                <ListItem>
                  Change from one Medicare Advantage Plan to another
                </ListItem>
                <ListItem>
                  Add prescription drug coverage (Medicare Part D)
                </ListItem>
              </List>
              <Typography variant="body">
                <strong>What you can't do:</strong>
              </Typography>
              <List>
                <ListItem>
                  Enroll in Original Medicare for the first time
                </ListItem>
                <ListItem>
                  Enroll in a Medicare Advantage Plan for the first time
                </ListItem>
                <ListItem>
                  Enroll in a Medicare Supplement (Medigap) Plan without
                  underwriting health questions
                </ListItem>
              </List>
              <Typography variant="body">
                Changes in coverage during MAOEP take effect July 1 of that
                year.
              </Typography>
            </>
          }
        />

        <VariableContent
          className="gray-bg bg-overwrite"
          mainContent={
            <>
              <Typography variant="h2" className="text-center">
                Get Medicare Enrollment Period Help{' '}
              </Typography>
              <Typography variant="body" className="text-left-on-small">
                If you qualify for General Enrollment after missing your Initial
                Enrollment Period or have questions about Medicare Advantage
                plans, call{' '}
                <Link className="pink" to={`tel:${rotatedNumber}`}>
                  {rotatedNumber}
                </Link>{' '}
                to reach our US-based, US-licensed agents.
              </Typography>
            </>
          }
          alignMainContent="Center"
        ></VariableContent>

        <VariableContent
          mainContent={
            <>
              <Typography variant="h2" className="text-center">
                View these resources for more in-depth information
              </Typography>
              <Link to="https://www.cms.gov/priorities/key-initiatives/medicare-open-enrollment-partner-resources">
                https://www.cms.gov/priorities/key-initiatives/medicare-open-enrollment-partner-resources
              </Link>
              <Link to="https://www.cms.gov/priorities/key-initiatives/medicare-open-enrollment-partner-resources">
                https://www.cms.gov/training-education/find-provider-type/employers-unions/top-five-medicare-enrollment 
              </Link>
              <Link to="https://www.cms.gov/priorities/key-initiatives/medicare-open-enrollment-partner-resources">
                https://www.medicare.gov/basics/get-started-with-medicare/get-more-coverage/joining-a-plan 
              </Link>
              <Link to="https://www.cms.gov/priorities/key-initiatives/medicare-open-enrollment-partner-resources">
                https://www.medicare.gov/basics/get-started-with-medicare/sign-up/when-does-medicare-coverage-start  
              </Link>
              <Link to="https://www.cms.gov/priorities/key-initiatives/medicare-open-enrollment-partner-resources">
                https://www.medicarefaq.com/faqs/medicare-general-enrollment-period/ 
              </Link>
              <Link to="https://www.cms.gov/priorities/key-initiatives/medicare-open-enrollment-partner-resources">
                https://www.ncoa.org/article/a-closer-look-at-the-medicare-general-enrollment-period
              </Link>
            </>
          }
          alignMainContent="Left"
        ></VariableContent>

        <div className="purple-tri-background-no-dots">
          <SplitContent
            alignImageToBottom={false}
            image={<img src={Agent} alt="Customer service agent" />}
            mobileImage={false}
            mainContent={
              <>
                <Typography variant="h1">Who We Are</Typography>
                <Typography variant="body">
                  Clearlink Insurance has more than 300 licensed agents
                  nationwide. We work with the top insurance providers in the
                  country to help you narrow down the best options for you, the
                  customer.
                </Typography>
                <Typography variant="body">
                  If you qualify for one of the Medicare enrollment periods
                  discussed on this page, click the button below to begin.
                </Typography>
                <LinkButton
                  to="https://www.sunfirematrix.com/app/consumer/clearlink/#/"
                  color="pink"
                >
                  Get Started
                </LinkButton>
              </>
            }
          />
        </div>

        <VariableContent
          mainContent={
            <>
              <Typography variant="h4">
                Our Insurance Brand Partners{' '}
              </Typography>
            </>
          }
          alignMainContent="Center"
          alignImageToBottom={false}
          centeredContent={false}
          backgroundColor={null}
          customLink={null}
          className="top-bottom-padding-48 brand-logos"
        >
          <ResponsiveGrid
            images={brandLogos}
            numberOfColumns={6}
            numberOfMobileColumns={2}
            constraint="large"
            columnGap="10px"
          />
        </VariableContent>

        <div className="dual-button-banner">
          <NarrowBanner
            backgroundColor="#F004B9"
            leftButton={
              <LinkButton to={`tel:${rotatedNumber}`} color="white">
                <span className="mobile-text">Call Now</span>
                <span className="desktop-text">
                  Call Now to Speak to an Agent {rotatedNumber}
                </span>
              </LinkButton>
            }
            rightButton={
              <LinkButton
                to="https://my.clearlinkinsurance.com/login"
                color="white"
              >
                User Portal Login
              </LinkButton>
            }
          />
        </div>
      </>
    ),
  }

  return <Layout {...layoutProps} />
}
export default Home
